/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { useState } from "react";
import routes from "../routes";
import manyPosterLogo from "../../../images/many-poster-logo.png";

const Sidebar = ({ open, onClose, onMinimize }) => {
  const [isMinimize, setIsMinimize] = useState(true);
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all md:!z-50 lg:!z-50 xl:!z-0 w-[80px] border-r border-stone-200 ${open ? "translate-x-0" : "-translate-x-96"}`}>
      <span
        className="absolute top-1 right-1 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`flex items-center transition-all duration-100 justify-center px-2 h-[80px] border-b border-stone-200`}>
          <img src={manyPosterLogo} alt="manyposter" className="w-8"/>
      </div>
      {/* Nav item */}
      <ul className="mb-auto pt-4 pl-0">
        <Links routes={routes} isMinimize={isMinimize} />
      </ul>
      {/* <div className="flex justify-end px-4">
        <span
          className="flex cursor-pointer text-xl text-gray-600"
          onClick={() => {setIsMinimize(!isMinimize);onMinimize()}}
        >
          {isMinimize ? <FiArrowRight className="h-5 w-5" /> : <FiArrowLeft className="h-5 w-5" />}
        </span>
      </div> */}

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
