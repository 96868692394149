import React, { useState } from "react";
import Tooltip from "./tooltip";
import { CopyIcon } from "./customIcons";

const CopyButton = (props) => {
  const { textToCopy, includeHtml = false } = props;
  const [copySuccess, setCopySuccess] = useState("");

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  const handleHtml = (selector) => {
    // const contentElement = document.getElementById('sales_content');
    const contentElement = selector.current;
    if (contentElement) {
      const textToCopy = contentElement.innerText; // Get the text content
      // onClick();
      copyToClipboard(textToCopy.trim())
    } else {
      console.error('Element not found');
    }
  };

  return (
    <Tooltip text={copySuccess}>
      <div
        className="flex justify-center items-center w-6 h-6 rounded bg-slate-100 ml-1 cursor-pointer text-slate-500"
        onClick={() => includeHtml ? handleHtml(textToCopy) : copyToClipboard(textToCopy)}
      >
        <CopyIcon />
      </div>
    </Tooltip>
  );
};

export default CopyButton;
