import * as React from "react"

const ShareIcon = (props) => (
    <svg {...props} width="78" height="70" viewBox="0 0 78 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M57.2707 0.53186L52.9021 4.83453L65.7785 17.4573H35.9107C26.5437 17.4573 18.9192 24.9638 18.9192 34.1925C18.9192 43.4212 26.5437 50.9277 35.9107 50.9277H37.4553V44.8422H35.9107C29.9482 44.8422 25.0979 40.065 25.0979 34.1925C25.0979 28.32 29.9482 23.5428 35.9107 23.5428H65.7906L52.9263 36.2131L57.2948 40.5158L77.617 20.5L57.2707 0.53186ZM0.382996 2.24342V69.1843H68.349V38.7566L62.1702 44.8422V63.0988H6.56172V8.32896H40.0922L46.2709 2.24342H0.382996Z"
            fill="currentColor" />
    </svg>
)
const CommentIcon = (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 56.17">
        <path fill="currentColor" d="M64,25.15a22.14,22.14,0,0,0-7.35-16A27.62,27.62,0,0,0,54,6.85,36.74,36.74,0,0,0,32,0C14.36,0,0,11.28,0,25.15,0,32,3.46,38.4,9.77,43.2L8.54,55A1.09,1.09,0,0,0,9,56a1.05,1.05,0,0,0,.66.22.92.92,0,0,0,.4-.08l15.85-6.27a40.47,40.47,0,0,0,16.84-1C44,48.51,45,48.15,46,47.76,57.11,43.52,64,34.85,64,25.15ZM45.22,45.72a32,32,0,0,1-3.1,1,38.18,38.18,0,0,1-16.18.85,1.09,1.09,0,0,0-.59.07L10.91,53.39,12,43.33l0,0a1.09,1.09,0,0,0-.32-1.42C5.55,37.46,2.19,31.52,2.19,25.15c0-12.66,13.37-23,29.81-23A34.59,34.59,0,0,1,52.65,8.61a24.81,24.81,0,0,1,2.49,2.09h0c4.36,4.13,6.67,9.12,6.67,14.45C61.81,33.92,55.46,41.8,45.22,45.72Z" />
    </svg>
)
const RetweetIcon = (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 50.56">
        <path fill="currentColor" d="M36.89,46.18H20.6a6.33,6.33,0,0,1-6.32-6.32V7.48l6.15,6.15a2.19,2.19,0,1,0,3.1-3.09L13.65.65,13.47.49h0l0,0h0l0,0,0,0h0l0,0h0l0,0h0l0,0h0l0,0h-.08l0,0H13l0,0h0l-.05,0h0l-.05,0h0A2.17,2.17,0,0,0,12.3,0h-1l0,0h0a2.29,2.29,0,0,0-.69.47l-9.9,9.9a2.19,2.19,0,0,0,1.55,3.74,2.22,2.22,0,0,0,1.55-.64L9.89,7.48V39.86A10.69,10.69,0,0,0,10.74,44,10.84,10.84,0,0,0,13,47.43a10.73,10.73,0,0,0,3.4,2.29,10.6,10.6,0,0,0,4.17.84H36.89a2.19,2.19,0,1,0,0-4.38Z" /><path fill="currentColor" d="M27.11,4.38H43.4a6.33,6.33,0,0,1,6.32,6.32V43.08l-6.15-6.15a2.19,2.19,0,0,0-3.1,3.1l9.88,9.88.18.16h0l0,0h0l0,0,0,0h0l0,0h0l0,0h0l0,0h0l0,0h.07l0,0H51l0,0h0l.05,0h0l0,0h0a2.17,2.17,0,0,0,.56.13h1l0,0h0a2.29,2.29,0,0,0,.69-.47l9.9-9.9a2.19,2.19,0,0,0-3.1-3.1l-6.15,6.16V10.7a10.6,10.6,0,0,0-.85-4.16A10.68,10.68,0,0,0,47.57.84,10.6,10.6,0,0,0,43.4,0H27.11a2.19,2.19,0,1,0,0,4.38Z" />
    </svg>
)
const HeartIcon = (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 56.58">
        <path fill="none" strokeWidth={4} d="M59.66,5.34a16.53,16.53,0,0,0-23.37,0L32.5,9.13,28.71,5.34A16.53,16.53,0,0,0,5.34,28.71L9.13,32.5,32.5,55.87,55.87,32.5l3.79-3.79A16.53,16.53,0,0,0,59.66,5.34Z" stroke="currentColor" strokeMiterlimit={10} />
    </svg>
)
const SaveIcon = (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 85.31">
        <path fill="none" strokeWidth={4} d="M64.2.5.85.56h0a.31.31,0,0,0-.3.3L.5,84.5a.31.31,0,0,0,.18.28.27.27,0,0,0,.12,0,.3.3,0,0,0,.2-.08L31.63,57.78a1.3,1.3,0,0,1,.87-.33,1.31,1.31,0,0,1,.87.33l30.56,27a.32.32,0,0,0,.2.07.27.27,0,0,0,.12,0,.28.28,0,0,0,.18-.27L64.5.8a.29.29,0,0,0-.09-.21A.29.29,0,0,0,64.2.5Z" stroke="currentColor" strokeMiterlimit={10} />
    </svg>
)
const UploadIcon = (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.35 64">
        <path fill="currentColor" d="M31.13,6.35V48.43a2,2,0,1,1-3.91,0V6.35L16.33,17.24a2,2,0,0,1-2.77,0,1.94,1.94,0,0,1,0-2.78L29.24,0h.64L30,.15l.12.13.53.52,1.73,1.7c1.68,1.64,3.37,3.26,5.06,4.89l4.45,4.3c1,.92,1.92,1.84,2.86,2.78A2,2,0,1,1,42,17.24L31.13,6.35ZM3.91,60.09V31.39a2,2,0,1,0-3.91,0V62.05a2,2,0,0,0,2,2H56.39a2,2,0,0,0,2-2V31.39a2,2,0,1,0-3.91,0v28.7Z" fillRule="evenodd" />
    </svg>
)
const TweeterBadgeIcon = (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64.1">
        <path d="M63.79,30.13a11.63,11.63,0,0,0-6.27-8.35c-.24-.13-.34-.23-.23-.53a11.33,11.33,0,0,0,.47-5.45,10.64,10.64,0,0,0-3.31-6.57,11.46,11.46,0,0,0-11.64-2.5c-.3.09-.42,0-.56-.24a11.36,11.36,0,0,0-4-4.51A10.7,10.7,0,0,0,30.75.1a11.54,11.54,0,0,0-9,6.43c-.14.25-.25.28-.51.2a11,11,0,0,0-3.76-.6,10.75,10.75,0,0,0-2.24.21A10.73,10.73,0,0,0,9,9.75,11.57,11.57,0,0,0,6.72,21.2c.1.32,0,.45-.25.6a11.53,11.53,0,0,0-4.2,3.58A10.64,10.64,0,0,0,0,32.2,11.58,11.58,0,0,0,6.49,42.31c.26.14.35.25.24.57a11.42,11.42,0,0,0-.4,5.91A10.58,10.58,0,0,0,9.64,55a11.57,11.57,0,0,0,11.54,2.42c.3-.09.43-.05.57.23a11.38,11.38,0,0,0,3.61,4.24A10.81,10.81,0,0,0,33.2,64a11.59,11.59,0,0,0,9.07-6.42c.14-.27.25-.3.53-.2a11.06,11.06,0,0,0,5.53.54,11.38,11.38,0,0,0,9-15c-.13-.35-.07-.49.26-.66a11,11,0,0,0,3.88-3.19A10.85,10.85,0,0,0,63.79,30.13ZM46.55,24.31Q37.11,34.6,27.69,44.91c-.07.09-.14.16-.23.26l-.32-.29-5.6-5.6q-2.74-2.76-5.5-5.5c-.23-.23-.25-.34,0-.58Q18,31.3,19.86,29.34c.25-.26.37-.24.61,0,2.13,2.16,4.28,4.29,6.41,6.45.28.28.4.28.67,0l14.37-15.7c.25-.28.38-.26.64,0q2,1.84,4,3.66C46.74,23.94,46.78,24.05,46.55,24.31Z" fill="#1d9aef" />
    </svg>
)
const CopyIcon = (props) => (
    <svg
    {...props}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3438 16.875H6.46875C5.79742 16.875 5.15359 16.6083 4.67889 16.1336C4.20418 15.6589 3.9375 15.0151 3.9375 14.3438V6.46875C3.9375 5.79742 4.20418 5.15359 4.67889 4.67889C5.15359 4.20418 5.79742 3.9375 6.46875 3.9375H14.3438C15.0151 3.9375 15.6589 4.20418 16.1336 4.67889C16.6083 5.15359 16.875 5.79742 16.875 6.46875V14.3438C16.875 15.0151 16.6083 15.6589 16.1336 16.1336C15.6589 16.6083 15.0151 16.875 14.3438 16.875Z"
      fill="currentColor"
    />
    <path
      d="M5.625 2.8125H13.9177C13.7426 2.31934 13.4193 1.89242 12.9921 1.59029C12.5648 1.28816 12.0545 1.12563 11.5312 1.125H3.65625C2.98492 1.125 2.34109 1.39168 1.86639 1.86639C1.39168 2.34109 1.125 2.98492 1.125 3.65625V11.5312C1.12563 12.0545 1.28816 12.5648 1.59029 12.9921C1.89242 13.4193 2.31934 13.7426 2.8125 13.9177V5.625C2.8125 4.87908 3.10882 4.16371 3.63626 3.63626C4.16371 3.10882 4.87908 2.8125 5.625 2.8125Z"
      fill="currentColor"
    />
  </svg>
)
const CircleLoadingIcon = (props) => (
    <svg
        {...props}
        className="animate-spin ml-4 h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
    >
        <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
        ></circle>
        <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
    </svg>
)
const DashboardIcon = props => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="20" height="17" viewBox="0 0 20 17">
        <path d="M8.0001 16V11H12.0001V16C12.0001 16.55 12.4501 17 13.0001 17H16.0001C16.5501 17 17.0001 16.55 17.0001 16V8.99997H18.7001C19.1601 8.99997 19.3801 8.42997 19.0301 8.12997L10.6701 0.599971C10.2901 0.259971 9.7101 0.259971 9.3301 0.599971L0.970098 8.12997C0.630098 8.42997 0.840098 8.99997 1.3001 8.99997H3.0001V16C3.0001 16.55 3.4501 17 4.0001 17H7.0001C7.5501 17 8.0001 16.55 8.0001 16Z" fill="currentColor" />
    </svg>
);
export {
    ShareIcon,
    CircleLoadingIcon,
    CommentIcon,
    RetweetIcon,
    HeartIcon,
    SaveIcon,
    UploadIcon,
    TweeterBadgeIcon,
    CopyIcon,
    DashboardIcon
}