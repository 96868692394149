import React, { useState, useEffect } from "react";
// import { toast } from 'react-toastify';

const PostsPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    // const [allNavbarMenus, setAllNavbarMenus] = useState([]);
    // const [allHighlightsMenus, setAllHighlightsMenus] = useState([]);
    // const [allFooterMenus, setAllFooterMenus] = useState([]);
    // const [selectedMenuItem, setSelectedMenuItem] = useState();
    // const [showModal, setShowModal] = useState(false);
    // const [currentParentId, setCurrentParentId] = useState(0);
    // const [currentSection, setCurrentSection] = useState('main');
    const [activeTab, setActiveTab] = useState(0);
    // const [isLoading, setIsLoading] = useState(true);
    const tabs = [
        'Navbar',
        'Highlights',
        'Footer',
    ]
    // const columnsDataHighlightMenus = [
    //     {
    //         Header: "Image",
    //         accessor: "icon",
    //     },
    //     {
    //         Header: "Title",
    //         accessor: "title",
    //     },
    //     {
    //         Header: "Link",
    //         accessor: "url",
    //     },
    //     {
    //         Header: "Order",
    //         accessor: "order",
    //     },
    //     {
    //         Header: "Created Date",
    //         accessor: "created_at",
    //     },
    //     {
    //         Header: "Updated Date",
    //         accessor: "updated_at",
    //     },
    //     {
    //         Header: "Status",
    //         accessor: "status",
    //     },
    //     {
    //         Header: "Actions",
    //         accessor: 'id',
    //     },
    // ];
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */

    // const handleAddMenuItem = (_parentID, section) => {
    //     setCurrentParentId(_parentID);
    //     setCurrentSection(section);
    //     setShowModal(true);
    // }
    // const handleEditMenuItem = (_menu, section) => {
    //     console.log("menu=",_menu)
    //     setCurrentSection(section)
    //     setSelectedMenuItem(_menu);
    //     setShowModal(true);
    // }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        // getAllMenus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /* -------------------------------------------------------------------------- */
    return (
        <div className="flex flex-col w-full">
        <div className="flex w-full border-b border-stone-400 mb-4">
            {tabs.map((item, index) => {
                return <div
                    key={`tabs${index}`}
                    className={`flex justify-center items-center px-4 py-2 rounded-tl-md rounded-tr-md cursor-pointer ${activeTab === index ? 'bg-primaryDark text-white font-bold' : ' text-stone-600'}`}
                    onClick={() => setActiveTab(index)}
                >
                    {item}
                </div>
            })}
        </div>
    </div>
    );
};
export default PostsPage;