import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

const Logo = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { logoGenerated, isImageLoaded } = props;
    const [logoImage, setLogoImage] = useState();
    const [seconds, setSeconds] = useState(10);
    const [fadeClass, setFadeClass] = useState('fade');
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        if (seconds > 0) {
            const timer = setInterval(() => {
                setFadeClass('fade-out'); // Trigger fade-out effect
                setTimeout(() => {
                    setSeconds(prevSeconds => prevSeconds - 1);
                    setFadeClass('fade'); // Reset to fade-in for the next number
                }, 500); // Match this time with CSS transition duration
            }, 1000);

            // Cleanup the interval on component unmount
            return () => clearInterval(timer);
        }
    }, [seconds]);
    useEffect(() => {
        console.log("image=", logoGenerated)
        logoGenerated && setLogoImage(logoGenerated)
    }, [logoGenerated])
    useEffect(() => {
        !isImageLoaded && setSeconds(10)
    }, [isImageLoaded])
    /* -------------------------------------------------------------------------- */
    return (
        <div className="flex flex-wrap justify-center items-center relative w-full">
            <div className={`w-full- max-w-[400px]- bg-white rounded-xl p-2 md:p-4 basis-full md:basis-1/2`}>
                <div className="relative min-h-[272px] h-[294px]- md:h-[368px]-">
                    {isImageLoaded
                        ? logoImage && <div className="max-h-[368px]- rounded-lg overflow-hidden flex items-center">
                            <img src={logoImage} alt="post" className="w-full rounded-lg" />
                        </div>
                        : <>
                            {seconds ? <span className={`left-0 right-0 mx-auto top-[30%] z-[100] text-center text-9xl md:text-9xl font-semibold absolute text-stone-600 ${fadeClass}`}>{seconds}</span> : ''}
                            {seconds ? <span className={`left-0 right-0 mx-auto top-[70%] z-[100] text-center text-xs md:text-sm absolute text-stone-600`}>Keep staring into my eyes 👀</span> : ''}
                            <div className="hidden md:flex [&>span]:w-full"><Skeleton height={368} /></div>
                            <div className="md:hidden flex [&>span]:w-full"><Skeleton height={294} /></div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default Logo;