import React, { useEffect, useState } from 'react';
import DashboardLayout from './dashboard-layout';
import routes from './routes';
import { useLocation, useNavigate } from 'react-router-dom';

const DashboardPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem('token') || '';
  const [hasAccess, setHasAccess] = useState(false)
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    window.scroll(0, 0);
    // console.log("location", location.pathname);
    if(token === '' || token !== 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9_.eyJ1c2VySWQiOiIxMjM0NTY3ODkwIiwidXNlck5hbWUiOiJ1c2VyMSJ9.987tb6OkebqtGN08bLzt8uaHgaFe8_i5RdAI4qVJgGk') {
      navigate('/login')
    } else {
      setHasAccess(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  /* -------------------------------------------------------------------------- */
  return (
    <DashboardLayout>
      <div className="w-full flex flex-wrap px-3 py-4">
          {hasAccess && routes.map((route, index) => {
            // console.log("routes", route);
            if (route.path === location.pathname) {
              return <React.Fragment key={`key_root${index}`}>{route.component}</React.Fragment>
            } else {
              return null
            }
          })}
      </div>
      <div className="md:container md:mx-auto mt-0 md:mt-2">
        <div className="grid grid-cols-12 px-4 md:px-2 lg:px-0">
          {/* /* ------------------------------ Start Top Appeals ------------------------- */}

          {/* /* -------------------------------------------------------------------------- */}
        </div>
      </div>
    </DashboardLayout>
  );
};
export default DashboardPage;