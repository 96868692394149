import React, { useEffect, useState } from "react";
import services from "../../services";
// import xIcon from "../../images/x-icon.png";
// import instagramIcon from "../../images/instagram-icon.png";
import logoBeta from "../../images/many-poster-beta.png";
// import { Link } from "react-router-dom";
// import CopyToClipboard from "../../utils/copyToClipBoard";
// import CopyButton from "../../components/copyButton";
import AutoExpandingTextarea from "../../components/autoExpandingTextarea";
// import XSocialPost from "../../components/xSocialPost";
import { useDispatch, useSelector } from "react-redux";
import { selectUserIP, setUserIP } from "../../store/user";
import Posts from "../../components/posts";
import { CircleLoadingIcon } from "../../components/customIcons";
import Poster from "../../components/poster";
import SalesLetter from "../../components/salesLetter";
import Logo from "../../components/logo";
import { LuSquare, LuRectangleHorizontal, LuRectangleVertical } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

const HomePage = ({type = 'social-post'}) => {
  /* ----------------------------- Start variables ---------------------------- */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]);
  const [imagesGenerated, setImagesGenerated] = useState([]);
  const [posterGenerated, setPosterGenerated] = useState();
  const [salesLetterGenerated, setSalesLetterGenerated] = useState();
  const [logoGenerated, setLogoGenerated] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSalesLetterLoaded, setIsSalesLetterLoaded] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [prompt, setPrompt] = useState();
  // const [searchTypes] = useState(['Social Post', 'Poster', 'Sales Letter', 'Logo']);
  const searchTypes = [
    { title: 'Social Post', description: 'Write me a social post about...' },
    { title: 'Poster', description: 'Design me a poster for…' },
    { title: 'Sales Letter', description: 'Write me a sales letter about...' },
    { title: 'Logo', description: 'Design me a logo for…' }
  ];
  const [selectedType, setSelectedType] = useState(searchTypes[0]);
  const userIP = useSelector(selectUserIP);
  const aspect_ratios = [
    { value: 'ASPECT_9_16', label: '9:16', icon: <LuRectangleVertical /> },
    { value: 'ASPECT_1_1', label: '1:1', icon: <LuSquare /> },
    { value: 'ASPECT_16_9', label: '16:9', icon: <LuRectangleHorizontal /> },
    // { value: 'ASPECT_16_10', label: 'Aspect 16:10' },
    // { value: 'ASPECT_10_16', label: 'Aspect 10:16' },
    // { value: 'ASPECT_1_3', label: 'Aspect 1:3' },
    // { value: 'ASPECT_2_3', label: 'Aspect 2:3' },
    // { value: 'ASPECT_3_1', label: 'Aspect 3:1' },
    // { value: 'ASPECT_3_2', label: 'Aspect 3:2' },
    // { value: 'ASPECT_3_4', label: 'Aspect 3:4' },
    // { value: 'ASPECT_4_3', label: 'Aspect 4:3' }
  ]
  const [selectedAspectRatio, setSelectedAspectRatio] = useState(aspect_ratios[0]);
  // const [socials, setSocials] = useState([
  //   {
  //     title: "Instagram",
  //     icon: instagramIcon,
  //     description:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //     isSelected: true,
  //     links: [
  //       {
  //         url: "https://www.instagram.com/",
  //         title: "www.instagram.com",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Twitter",
  //     icon: xIcon,
  //     description:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //     isSelected: false,
  //     links: [
  //       {
  //         url: "https://www.xsocialmedia.com/",
  //         title: "www.xsocialmedia.com",
  //       },
  //     ],
  //   },
  // ]);
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const handleSearch = (event) => {
    event.preventDefault();
    // if (prompt.text && userIP !== '113.30.189.161') {
    if (prompt) {
      if (selectedType.title === 'Poster') {
        generatePoster();
      } else if (selectedType.title === 'Sales Letter') {
        generateSalesLetter();
      } else if (selectedType.title === 'Logo') {
        generateLogo();
      } else {
        generateImage();
        generatePost();
      }
    }
  };
  const generatePoster = async () => {
    setIsImageLoaded(false);
    setPosterGenerated(null);
    let req = {
      description_image_generator: prompt,
      image_orientation: selectedAspectRatio.value
    };
    setIsLoading(true);
    try {
      const response = await services.getPoster(req);
      setPosterGenerated(response.data.data);
      setIsImageLoaded(true);
      setIsLoading(false);
      setIsDisabled(true); // Disable the button
      // Set a timeout to re-enable the button after 10 seconds
      setTimeout(() => {
        setIsDisabled(false); // Re-enable the button
      }, 10000); // 10000 milliseconds = 10 seconds
    } catch (error) {
      setIsImageLoaded(true);
      setIsLoading(false);
    }
  };
  const generateSalesLetter = async () => {
    setIsLoading(true);
    setIsSalesLetterLoaded(false);
    setSalesLetterGenerated('')
    let req = {
      business_description: prompt,
    };
    try {
      const response = await services.getSalesLetter(req);
      console.log("respo=", response)
      setSalesLetterGenerated(response.data.data[0]);
      setIsLoading(false);
      setIsSalesLetterLoaded(true);
      setIsDisabled(true); // Disable the button
      // Set a timeout to re-enable the button after 10 seconds
      setTimeout(() => {
        setIsDisabled(false); // Re-enable the button
      }, 10000); // 10000 milliseconds = 10 seconds
    } catch (error) {
      setIsSalesLetterLoaded(true);
      setIsLoading(false);
    }
  };
  const generatePost = async () => {
    // setRandomNumber(null);
    setSearchResults([]);
    let req = {
      business_description: prompt,
    };
    setIsLoading(true);
    try {
      const response = await services.getPost(req);
      setSearchResults(response.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const generateImage = async () => {
    setIsImageLoaded(false);
    setImagesGenerated([]);
    let req = {
      description_image_generator: prompt,
    };
    setIsDisabled(true); // Disable the button
    // Set a timeout to re-enable the button after 10 seconds
    setTimeout(() => {
      setIsDisabled(false); // Re-enable the button
    }, 10000); // 10000 milliseconds = 10 seconds
    try {
      const response = await services.getImage(req);
      setImagesGenerated([response.data.data]);
      setIsImageLoaded(true);
    } catch (error) { }
  };
  const generateLogo = async () => {
    setIsImageLoaded(false);
    let req = {
      description_image_generator: prompt,
      image_orientation: 'ASPECT_1_1'
    };
    setIsLoading(true);
    try {
      const response = await services.getLogo(req);
      setLogoGenerated(response.data.data);
      setIsImageLoaded(true);
      setIsLoading(false);
      setIsDisabled(true); // Disable the button
      // Set a timeout to re-enable the button after 10 seconds
      setTimeout(() => {
        setIsDisabled(false); // Re-enable the button
      }, 10000); // 10000 milliseconds = 10 seconds
    } catch (error) {
      setIsImageLoaded(true);
      setIsLoading(false);
    }
  };
  // const generateRandomNumber = () => {
  //   setRandomNumber((Math.random() * (0.3 - 0.1) + 0.1).toFixed(2)); // Generate a random number between 0.1 and 0.3
  // };
  // const [randomNumber, setRandomNumber] = useState(null);
  // const handleSelectSocial = (item, index) => {
  //   setSocials((prevItems) =>
  //     prevItems.map((item, i) => {
  //       if (i === index) {
  //         item.isSelected = true;
  //         setPrompt({ ...prompt, social: item.title });
  //       } else {
  //         item.isSelected = false;
  //       }
  //       return item;
  //     })
  //   );
  // };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    switch (type) {
      case 'social-post':
        setSelectedType(searchTypes[0])
        break;
      case 'poster':
        setSelectedType(searchTypes[1])
        break;
      case 'sales-letter':
        setSelectedType(searchTypes[2])
        break;
      case 'logo':
        setSelectedType(searchTypes[3])
        break;
      default:
        setSelectedType(searchTypes[0])
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
  useEffect(() => {
    if (userIP === "") {
      fetch("https://pro.ip-api.com/json?key=04S31DLsvcX7pze")
        .then((res) => res.json())
        .then((response) => {
          dispatch(setUserIP(response.query));
        })
        .catch((data) => {
          console.log("Request failed:", data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIP]);
  /* -------------------------------------------------------------------------- */
  return (
    <div className="flex flex-col h-full">
      <div
        className={`flex-1 md:container md:mx-auto py-4 px-4 md:px-0 h-screen- flex flex-col ${searchResults.content ? "justify-start" : "justify-center"
          }`}
      >
        <div className="w-full flex justify-center items-center h-full-">
          <div className="w-full flex justify-center flex-wrap">
            <div className="basis-full">
              {/* <h1 className="text-lg md:text-2xl lg:text-3xl mb-3 md:mb-6 font-bold text-center">
            Write me a social post about...
          </h1> */}
              <img
                src={logoBeta}
                className="w-[100px] md:w-[200px] mx-auto mb-4"
                alt={"ManyPoster"}
              />
            </div>
            <form
              onSubmit={handleSearch}
              className="basis-full md:basis-1/2 flex flex-col items-center justify-center"
            >
              <div className="flex justify-center items-center gap-2 my-2 md:my-4">
                {searchTypes.map((item, index) => {
                  return <div
                  key={`Types${index}`}
                  className={`flex justify-center items-center transition-all duration-200 rounded-full hover:bg-gray-600 hover:text-white cursor-pointer border text-sm md:text-base px-3 h-9 md:h-12 md:px-4 ${selectedType.title.toLocaleLowerCase() === item.title.toLocaleLowerCase() ? 'bg-gray-800 text-white' : 'border-stone-300 text-stone-600 bg-white'}`}
                  // onClick={() => setSelectedType(item)}>
                    onClick={() => navigate(`/${item.title.toLocaleLowerCase().replace(/ /g, '-')}`)}>
                    {item.title}
                  </div>
                })}
              </div>
              {/* {selectedType.title.toLocaleLowerCase() === 'poster' && <div className="mb-2 md:mb-4 md:min-w-[180px]">
                <ReactSelect
                  options={aspect_ratios}
                  className={`notranslate max-h-[48px] [&>*]:!cursor-pointer w-full [&>div]:!rounded-md`}
                  value={selectedAspectRatio}
                  isSearchable={false}
                  // styles={styles}
                  onChange={(e) => setSelectedAspectRatio(e)}
                />
              </div>} */}
              {selectedType.title.toLocaleLowerCase() === 'poster' && <div className="mb-2 md:mb-4 md:min-w-[180px]- flex justify-center rounded-md bg-gray-600 text-white p-1 md:p-2 gap-2">
                {aspect_ratios.map((item,index) => {
                  return <div key={`ratios_${index}`} className={`${selectedAspectRatio.label === item.label ? 'bg-gray-800' : ''} rounded-md cursor-pointer p-1 transition-all duration-300 flex justify-center items-center gap-1`} onClick={() => setSelectedAspectRatio(item)}>
                    {item.icon}{item.label}
                  </div>
                })}
              </div>}
              <AutoExpandingTextarea
                type="text"
                name="searchingMobile"
                className="focus:outline-none w-full h-10 border border-gray-400 px-3"
                placeholder={selectedType.description}
                value={prompt}
                onChange={(e) => {
                  setPrompt(e);
                }}
              />
              <div className="my-4 flex flex-col items-center">
                <button
                  type="submit"
                  disabled={isLoading || isDisabled}
                  className={`flex justify-center items-center px-4 md:px-8 text-md text-white bg-gray-800 focus:outline-none h-12 transition-all duration-200 rounded-full hover:bg-gray-600 ${isDisabled ? '!cursor-not-allowed !bg-gray-500' : ''}`}
                >
                  {isLoading ? (
                    <>
                      Processing...
                      <CircleLoadingIcon className='ml-4' />
                    </>
                  ) : (
                    "Generate"
                  )}
                </button>
                {/* {randomNumber && (
              <span className="text-sm text-stone-400">
                Made in {randomNumber} seconds
              </span>
            )} */}
              </div>
            </form>
          </div>
        </div>
        {/* {searchResults.length > 0 && (
          <XSocialPost
            content={searchResults[0]}
            image={imageGenerated}
            isImageLoaded={isImageLoaded}
            className="mx-auto"
          />
        )} */}
        {selectedType.title === 'Social Post' && searchResults.length > 0 && <Posts
          posts={searchResults}
          imagesGenerated={imagesGenerated}
          isImageLoaded={isImageLoaded}
        />}
        {selectedType.title === 'Poster' && (posterGenerated || isLoading) && <Poster posterGenerated={posterGenerated} isImageLoaded={isImageLoaded} />}
        {selectedType.title === 'Sales Letter' && (salesLetterGenerated || isLoading) && <SalesLetter salesLetterGenerated={salesLetterGenerated} isSalesLetterLoaded={isSalesLetterLoaded} />}
        {selectedType.title === 'Logo' && (logoGenerated || isLoading) && <Logo logoGenerated={logoGenerated} isImageLoaded={isImageLoaded} />}
        <footer className="flex text-stone-400 justify-center pt-4">
          Created using{" "}
          <a className="text-stone-500 ml-1" href="https://ManyPoster.com">
            ManyPoster.com
          </a>
        </footer>
      </div>
    </div>
  );
};

export default HomePage;