import React, { useEffect, useState } from "react";
import CopyButton from "./copyButton";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import avatar from '../images/elonmusk.jpg';
import { CommentIcon, HeartIcon, RetweetIcon, SaveIcon, TweeterBadgeIcon, UploadIcon } from "./customIcons";

const XSocialPost = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { content, image, isImageLoaded, className } = props;
    const currentTime = moment().format('h:mm A');
    const currentDate = moment().format('MMM D, YYYY');
    const [postImage, setPostImage] = useState();
    const [seconds, setSeconds] = useState(10);
    const [fadeClass, setFadeClass] = useState('fade');
    /* -------------------------------------------------------------------------- */

    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const RawHTML = ({ children, className = "" }) => (
        <div
            className={`overflow-hidden ${className}`}
            dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, "") }}
        />
    );
    // const renderText = () => {
    //     let temp = content;
    //     if (temp.startsWith('"') && temp.endsWith('"')) {
    //         return temp.slice(1, -1); // Remove the first and last character
    //     }
    //     return temp.split(' ').map((word, index) => {
    //         if (word.startsWith('#')) {
    //             return (
    //                 <span key={index} style={{ color: 'blue' }}>
    //                     {word}{' '}
    //                 </span>
    //             );
    //         }
    //         return word + ' ';
    //     });
    // };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        if (seconds > 0) {
            const timer = setInterval(() => {
                setFadeClass('fade-out'); // Trigger fade-out effect
                // setSeconds(prevSeconds => prevSeconds - 1);
                setTimeout(() => {
                    setSeconds(prevSeconds => prevSeconds - 1);
                    setFadeClass('fade'); // Reset to fade-in for the next number
                }, 500); // Match this time with CSS transition duration
            }, 1000);

            // Cleanup the interval on component unmount
            return () => clearInterval(timer);
        }
    }, [seconds]);
    useEffect(() => {
        // console.log("image=", image)
        image && setPostImage(image)
    }, [image])
    useEffect(() => {
        !isImageLoaded && setSeconds(10)
    }, [isImageLoaded])
    /* -------------------------------------------------------------------------- */
    return (
        <div className={`w-full max-w-[400px] bg-white rounded-xl p-2 md:p-4 ${className ? className : ''}`}>
            <div className="relative min-h-[272px] h-[294px]- md:h-[368px]">
                {isImageLoaded
                    ? postImage && <div className="max-h-[368px] rounded-lg overflow-hidden flex items-center">
                        <img src={postImage} alt="post" className="w-full rounded-lg" />
                    </div>
                    : <>
                        {seconds ? <span className={`left-0 right-0 mx-auto top-[30%] z-[100] text-center text-9xl md:text-9xl font-semibold absolute text-stone-600 ${fadeClass}`}>{seconds}</span> : ''}
                        {seconds ? <span className={`left-0 right-0 mx-auto top-[70%] z-[100] text-center text-xs md:text-sm absolute text-stone-600`}>Keep staring into my eyes 👀</span> : ''}
                        <div className="hidden md:flex [&>span]:w-full"><Skeleton height={368} /></div>
                        <div className="md:hidden flex [&>span]:w-full"><Skeleton height={294} /></div>
                    </>
                }
            </div>
            {/* {postImage && <div className="max-w-[200px] rounded-lg overflow-hidden mb-2">
                <img src={postImage} alt="post" className="w-full rounded-lg" />
            </div>} */}
            {/* <img src={'https://ideogram.ai/api/images/ephemeral/SkoXQoitQ32GVTPsPxTwZw.png?exp=1728841878&sig=11cbd616574490990adf85a8c409ffbc691a1b2eadb071d314c4f75479da0983'} alt="post" className="w-full rounded-lg mb-2" /> */}
            <div className="flex justify-between items-center my-2 md:my-4">
                <div className="flex items-center">
                    <div className="w-11 h-11 rounded-full overflow-hidden mr-2 flex items-center justify-center">
                        <img src={avatar} alt="profile" className="w-full" />
                    </div>
                    <div className="flex flex-col h-10">
                        <div className="flex items-center text-gray-700">
                        Elon Musk
                            <TweeterBadgeIcon className="ml-1 mt-0.5 w-4 h-4" />
                        </div>
                        <span className="text-sm text-gray-400 -mt-1">@elonmusk</span>
                    </div>
                </div>
                <CopyButton textToCopy={content} />
            </div>
            <div className="px-1 md:px-0">
                <RawHTML>{content}</RawHTML>
                {/* {renderText(content)} */}
                <div className="w-full flex border-b border-stone-200 py-2 items-center text-xs justify-center">
                    <span className="text-stone-400">
                        {currentTime}
                    </span>
                    <div className="w-1 h-1 rounded-full bg-stone-500 mx-1.5" />
                    <span className="text-stone-400">
                        {currentDate}
                    </span>
                    <div className="w-1 h-1 rounded-full bg-stone-500 mx-1.5" />
                    <span className="text-stone-400">
                        201K Views
                    </span>
                </div>
                <div className="w-full flex justify-between border-b border-stone-200 py-2 items-center text-xs">
                    <span className="text-stone-400 cursor-pointer flex items-center">
                        <CommentIcon className="w-4 h-4 mr-1 text-stone-400" />
                        991
                    </span>
                    <span className="text-stone-400 cursor-pointer flex items-center">
                        <RetweetIcon className="w-4 h-4 mr-1 text-stone-400" />
                        25
                    </span>
                    <span className="text-stone-400 cursor-pointer flex items-center">
                        <HeartIcon className="w-4 h-4 mr-1 text-stone-400" />
                        9,153
                    </span>
                    <span className="text-stone-400 cursor-pointer flex items-center">
                        <SaveIcon className="w-4 h-4 mr-1 text-stone-400" />
                        12
                    </span>
                    <span className="text-stone-400 cursor-pointer flex items-center">
                        <UploadIcon className="w-4 h-4 text-stone-400" />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default XSocialPost;