import React, { useState } from "react";
import { useKeenSlider } from 'keen-slider/react';
import { LiaAngleLeftSolid, LiaAngleRightSolid } from 'react-icons/lia';
import XSocialPost from "./xSocialPost";

const Posts = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { posts, imagesGenerated, isImageLoaded } = props;
    const [loaded, setLoaded] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const MutationPlugin = (slider) => {
        const observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                slider.update()
            })
        })
        const config = { childList: true }

        slider.on("created", () => {
            observer.observe(slider.container, config)
        })
        slider.on("destroyed", () => {
            observer.disconnect()
        })
    }
    const [sliderRef, instanceRef] = useKeenSlider({
        mode: 'snap',
        loop: true,
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
        created() {
            setLoaded(true);
        },
        breakpoints: {
            '(min-width: 320px)': {
                slides: { perView: 1, spacing: 10 },
            },
            '(min-width: 768px)': {
                slides: { perView: 1, spacing: 10 },
            },
            '(min-width: 1024px)': {
                slides: { perView: 1, spacing: 10 },
            },
            '(min-width: 1280px)': {
                slides: { perView: 1, spacing: 10 },
            },
        },
        slides: {
            origin: 'center',
        },
        // eslint-disable-next-line no-use-before-define
    }, [MutationPlugin]);
    /* -------------------------------------------------------------------------- */

    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */

    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */

    /* -------------------------------------------------------------------------- */
    return (
        <div className="w-full flex-wrap justify-center items-center relative">
            {posts.length > 1 && <>
                <div className='absolute translate-y-1/2 h-6 top-[47%] -left-4 z-10'
                    onClick={e => e.stopPropagation() || instanceRef.current?.prev()}
                >
                    <LiaAngleLeftSolid color='white' size={30} />
                </div>
                <div className='absolute translate-y-1/2 h-6 top-[47%] -right-4 z-10'
                    onClick={e => e.stopPropagation() || instanceRef.current?.next()}
                >
                    <LiaAngleRightSolid color='white' size={30} />
                </div>
            </>}
            <div ref={sliderRef} className="keen-slider flex justify-center overflow-hidden">
                {posts && posts.map((item, index) => {
                    return (
                        <div key={`active_carousel_${index}`} className="keen-slider__slide basis-full flex justify-center pb-4">
                            <div className="w-full max-w-[400px]">
                                <XSocialPost
                                    content={item}
                                    image={imagesGenerated[index]}
                                    isImageLoaded={isImageLoaded}
                                    className="mx-auto"
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
            {posts && posts.length > 1 && (
                <div className="flex md:hidden bg-white py-3 px-2 rounded-md justify-center items-center mx-auto mt-2 max-w-[300px]">
                    {loaded && instanceRef.current && instanceRef.current.track.details && (
                        <div className="flex justify-center items-center relative w-full">
                            {[...Array(instanceRef.current.track.details.slides.length).keys()].map(idx => {
                                return (
                                    <button
                                        key={`q${idx}`}
                                        onClick={() => instanceRef.current ? instanceRef.current.moveToIdx(idx) : null}
                                        className={
                                            'md:w-4 md:mx-2 mx-1 md:h-4 h-3  w-3 rounded-full ' +
                                            (currentSlide === idx ? ' bg-LightBlue hover:opacity-60' : 'bg-[#C3C3C3]')
                                        }
                                    ></button>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Posts;