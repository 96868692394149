import React, { useRef, useState, useEffect } from "react";

const AutoExpandingTextarea = (props) => {
  const { value, placeholder, name, className, onChange } = props;
  const [currentValue, setCurrentValue] = useState(value);
  // const [isMultiLines, setIsMultiLines] = useState(false);
  const textareaRef = useRef(null);

  const handleChange = (event) => {
    setCurrentValue(event.target.value);
    onChange(event.target ? event.target.value : '');
  };

  useEffect(() => {
    // Reset height to auto to calculate scrollHeight correctly
    textareaRef.current.style.height = "auto";
    // Set height to scrollHeight to expand the textarea
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    // console.log("height=",textareaRef.current.style.height,textareaRef.current.clientHeight)
    // if(textareaRef.current.clientHeight > 48) {
    //   setIsMultiLines(true)
    // } else {
    //   setIsMultiLines(false)
    // }
  }, [currentValue]); // Run effect when value changes

  return (
    <textarea
      ref={textareaRef}
      type="text"
      value={currentValue}
      name={name}
      onChange={handleChange}
      placeholder={placeholder}
      rows={1} // Start with one row
      // className={`w-full overflow-hidden resize-none transition-all duration-200 ${isMultiLines ? 'rounded-lg p-3 ' : 'rounded-full p-2'} ${className ? className : ''}`}
      className={`w-full overflow-hidden resize-none transition-all duration-200 p-2 rounded-3xl ${className ? className : ''}`}
    />
  );
};

export default AutoExpandingTextarea;
