import { createSlice } from '@reduxjs/toolkit';

export const postsSlice = createSlice({
    name: 'posts',
    initialState: {
        postsState: {}
    },
    reducers: {
        setPostsState(state, action) {
            state.postsState = action.payload;
        },
    }
});

export const { setPostsState} = postsSlice.actions;
export const selectPostsState = (state) => state.posts.postsState;

export default postsSlice.reducer;