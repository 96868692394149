import React, { useState, useEffect } from 'react';

const Tooltip = ({ text, children }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        setVisible(false);
      }, 2000); // Tooltip visible for 2 seconds
      return () => clearTimeout(timer);
    }
  }, [visible]);

  return (
    <div className='inline-block'>
      <div
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        {children}
      </div>
      {visible && <div className="absolute bg-slate-500 text-white p-1 rounded z-[1000] mt-1 whitespace-nowrap max-[767px]:right-0">{text}</div>}
    </div>
  );
};

export default Tooltip;