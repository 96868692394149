/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import RouteContext from './context/route';
import HomePage from './pages/home';
import LoginPage from './pages/dashboard/pages/login';
import DashboardPage from './pages/dashboard';

const AllRoutes = () => {
  /* ----------------------------- Start variables ---------------------------- */
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */

  /* -------------------------------------------------------------------------- */
  return (
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/social-post" element={<HomePage type="social-post"/>} />
        <Route path="/poster" element={<HomePage type="poster"/>} />
        <Route path="/sales-letter" element={<HomePage type="sales-letter"/>} />
        <Route path="/logo" element={<HomePage type="logo"/>} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
      </Routes>
    // <RouteContext.Provider>
    // </RouteContext.Provider>
  );
};

export default AllRoutes;
