import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
// import CopyButton from "./copyButton";
import {
    LuArrowDownToLine,
    LuCopy
} from "react-icons/lu";
import { toast } from "react-toastify";

const SalesLetter = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { salesLetterGenerated, isSalesLetterLoaded } = props;
    const [seconds, setSeconds] = useState(5);
    const [fadeClass, setFadeClass] = useState('fade');
    const contentRef = useRef(null);
    const RawHTML = ({ children, className = "" }) => (
        <div
            className={`overflow-hidden ${className}`}
            dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, "") }}
        />
    );
    const handleDownload = () => {
        const input = document.getElementById('sales_content'); // Get the content div

        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgWidth = 190; // Width of the image in the PDF
            const pageHeight = pdf.internal.pageSize.height;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;

            let position = 0;

            // Add the image to the PDF
            pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            // If the image height is greater than the page height, add new pages
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            // Save the PDF
            pdf.save('download.pdf');
        });
    };
    const selectAllText = async () => {
        const contentElement = document.getElementById('sales_content'); // Get the content element
        if (contentElement) {
            const html = contentElement.innerHTML; // Get the inner HTML of the content
            // Create a temporary div element to facilitate copying with styles
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = html; // Set the inner HTML to the content
            tempDiv.style.position = 'absolute'; // Position off-screen
            tempDiv.style.opacity = '0'; // Make it invisible
            document.body.appendChild(tempDiv); // Append to body

            // Create a range and select the contents of the temporary div
            const range = document.createRange();
            range.selectNodeContents(tempDiv);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range); // Select the range

            try {
                await navigator.clipboard.write([
                    new ClipboardItem({
                        'text/html': new Blob([tempDiv.innerHTML], { type: 'text/html' }),
                        'text/plain': new Blob([contentElement.innerText], { type: 'text/plain' })
                    })
                ]);
                toast.info('Text copied to clipboard!');
                // alert('Styled text copied to clipboard!');
            } catch (err) {
                console.error('Error copying styled text: ', err);
            } finally {
                // Clean up: remove the temporary div and clear the selection
                selection.removeAllRanges();
                document.body.removeChild(tempDiv);
            }
        }
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        if (seconds > 0) {
            const timer = setInterval(() => {
                setFadeClass('fade-out'); // Trigger fade-out effect
                setTimeout(() => {
                    setSeconds(prevSeconds => prevSeconds - 1);
                    setFadeClass('fade'); // Reset to fade-in for the next number
                }, 500); // Match this time with CSS transition duration
            }, 1000);

            // Cleanup the interval on component unmount
            return () => clearInterval(timer);
        }
    }, [seconds]);
    useEffect(() => {
        !isSalesLetterLoaded && setSeconds(5)
    }, [isSalesLetterLoaded])
    /* -------------------------------------------------------------------------- */
    return (
        <div className="flex flex-wrap justify-center items-center relative w-full" id="sales_letter">
            <div className={`bg-white rounded-xl p-2 md:p-4 basis-full md:basis-1/2`}>
                {isSalesLetterLoaded
                    ? salesLetterGenerated && <div className="relative min-h-[100px] md:min-h-[200px] p-2 md:p-4 bg-stone-100 rounded-md">
                        <div className="flex justify-end items-center gap-1">
                            {/* <CopyButton includeHtml textToCopy={contentRef} /> */}
                            <button onClick={selectAllText} className="p-2 border border-stone-200 text-slate-500 rounded transition-all duration-300 hover:bg-slate-500 hover:text-white">
                                <LuCopy />
                            </button>
                            <button onClick={handleDownload} className="p-2 border border-stone-200 text-slate-500 rounded transition-all duration-300 hover:bg-slate-500 hover:text-white">
                                <LuArrowDownToLine />
                            </button>
                        </div>
                        <div className="overflow-hidden flex items-center" id="sales_content" ref={contentRef}>
                            <RawHTML>{salesLetterGenerated}</RawHTML>
                        </div>
                    </div>
                    : <>
                        {seconds ? <span className={`left-0 right-0 mx-auto top-[30%] z-[100] text-center text-9xl md:text-9xl font-semibold absolute text-stone-600 ${fadeClass}`}>{seconds}</span> : ''}
                        {seconds ? <span className={`left-0 right-0 mx-auto top-[70%] z-[100] text-center text-xs md:text-sm absolute text-stone-600`}>Keep staring into my eyes 👀</span> : ''}
                        <div className="hidden md:flex [&>span]:w-full"><Skeleton height={368} /></div>
                        <div className="md:hidden flex [&>span]:w-full"><Skeleton height={294} /></div>
                    </>
                }
            </div>
        </div>
    );
};

export default SalesLetter;